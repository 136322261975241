import { combineReducers } from "redux";
import auth from "./auth";
import alerts from "./alerts";
import users from "./users";
import publicLetters from "./publicLetters";
import publicPhotos from "./publicPhotos";
import publicVideos from "./publicVideos";
import recipes from "./recipes";
import activities from "./activities";
import songs from "./songs";

export default combineReducers({
  auth,
  alerts,
  users,
  publicLetters,
  publicPhotos,
  publicVideos,
  recipes,
  activities,
  songs,
});
