import React from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 코스모스 from "../../../images/코스모스.png";

const UploadCosmos = ({
  modelVisible,
  setModalVisible,
  modalInfo,
  setModalInfo,
}) => {
  return (
    <React.Fragment>
      <p className="uploadDirection">코스모스를 눌러보세요^^ -&gt;</p>
      <div
        className="uploadCosmos"
        onClick={() =>
          modalInfo ? setModalInfo(modalInfo) : setModalVisible(!modelVisible)
        }
      >
        <img src={코스모스} alt="코스모스" />
      </div>
    </React.Fragment>
  );
};

UploadCosmos.propTypes = {
  modelVisible: PropTypes.bool,
  setModalVisible: PropTypes.func,
  modalInfo: PropTypes.object,
  setModalInfo: PropTypes.func,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(UploadCosmos);
