import axios from "axios";
import {
  ROLES_LOADED,
  ROLES_LOADED_ERROR,
  USERS_LOADED,
  USERS_LOADED_ERROR,
  ROLE_TOGGLED,
  ROLE_TOGGLED_ERROR,
} from "./types.js";

export const getRoles = () => async (dispatch) => {
  try {
    const res = await axios.get("/users/getRoles");

    dispatch({
      type: ROLES_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ROLES_LOADED_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/users/getUsers");

    dispatch({
      type: USERS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USERS_LOADED_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const toggleRole =
  (userRole = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(userRole);

    try {
      const res = await axios.put("/users/toggleRole", body, config);

      dispatch({
        type: ROLE_TOGGLED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ROLE_TOGGLED_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };
