import axios from "axios";
import { setAlert } from "./alerts";
import {
  SONGS_LOADED,
  SONGS_LOADED_ERROR,
  WRITE_SONG,
  WRITE_SONG_ERROR,
  EDIT_SONG,
  EDIT_SONG_ERROR,
} from "./types.js";
import { uploadFileToS3 } from "../utils/s3uploader";

export const getSongs = () => async (dispatch) => {
  try {
    const res = await axios.get("/songs/getSongs");

    dispatch({
      type: SONGS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SONGS_LOADED_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const writeSong =
  (song = {}) =>
  async (dispatch) => {
    await uploadFileToS3({
      key: song.song,
      file: song.songFile,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(song);
    try {
      const res = await axios.post("/songs/writeSong", body, config);

      dispatch({
        type: WRITE_SONG,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "songWritten", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: WRITE_SONG_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      dispatch(setAlert({ msg: "songNotWritten", alertType: "danger" }));
    }
  };

export const editSong =
  (song = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(song);

    try {
      const res = await axios.put("/songs/editSong", body, config);

      dispatch({
        type: EDIT_SONG,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "songEdited", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: EDIT_SONG_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
      dispatch(setAlert({ msg: "songNotEdited", alertType: "danger" }));
    }
  };
