import React, { useEffect, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { getActivities } from "../../../actions/activities";
import moment from "moment";
import "moment/locale/ko";
import { checkRoles } from "../../../utils/library";

const ActivitiesPage = ({
  user,
  activities,
  getActivities,
  isAuthenticated,
}) => {
  const [startOfFromDate, setStartOfFromDate] = useState(null);

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  if (!isAuthenticated) return <Navigate to="/" />;

  return (
    <div className="activitiesPage">
      {user && checkRoles(user.roles) && (
        <React.Fragment>
          <div className="activitiesHeader">
            <p>사랑하는 엄마</p>
          </div>
          <input
            type="number"
            className="numInput"
            onChange={(e) => {
              const numValue = e.target.value;
              if (numValue) {
                setStartOfFromDate(
                  moment().subtract(numValue, "days").startOf("day")
                );
              } else {
                setStartOfFromDate(null);
              }
            }}
            placeholder="숫자를 입력하세요"
            aria-label="Enter a number"
          />
          <div className="activities">
            {activities.length > 0 &&
              activities.map(({ name, locations }, i) => (
                <div key={i} className="activity">
                  <p>{name}</p>
                  {locations.length > 0 &&
                    locations
                      .filter(({ date }) => {
                        if (startOfFromDate) {
                          const startOfDate = moment(date).startOf("day");
                          return startOfDate.isSameOrAfter(startOfFromDate);
                        } else {
                          return true;
                        }
                      })
                      .map(({ today, locationsVisited }, i) => (
                        <div key={i}>
                          <p>{today}</p>
                          <ul>
                            {locationsVisited.length > 0 &&
                              locationsVisited.map(({ location, date }, i) => (
                                <li key={i}>{`${location} - ${moment(
                                  date
                                ).format("MMMM Do YYYY, h:mm:ss a")}`}</li>
                              ))}
                          </ul>
                        </div>
                      ))}
                </div>
              ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

ActivitiesPage.propTypes = {
  activities: PropTypes.array,
  getActivities: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  activities: state.activities.activities,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getActivities,
})(ActivitiesPage);
