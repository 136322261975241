import React, { useRef, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  uploadPublicPhoto,
  editPublicPhoto,
} from "../../../../actions/publicPhotos";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";

const PhotoFormModal = ({
  user,
  modalInfo,
  uploadPublicPhoto,
  editPublicPhoto,
  setModalInfo,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    publicPhoto: "",
    publicPhotoFile: "",
    publicPhotoLocation: "",
  });
  const { name, publicPhoto, publicPhotoFile, publicPhotoLocation } = formData;

  const nameInput = useRef(null);
  const publicPhotoInput = useRef(null);
  const publicPhotoLocationInput = useRef(null);
  const previewImage = useRef(null);

  const onChange = (e) => {
    if (modalInfo.type === "new") {
      if (e.target.files) {
        previewImage.current.src = URL.createObjectURL(e.target.files[0]);
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          publicPhoto: `${uuidv4()}-publicPhoto-${e.target.files[0].name}`,
          publicPhotoFile: e.target.files[0],
        });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else if (modalInfo.type === "edit") {
      if (e.target.name === "name") {
        setFormData({
          name: e.target.value,
          publicPhotoLocation: publicPhotoLocation
            ? publicPhotoLocation
            : modalInfo.publicPhotoLocation,
        });
      } else if (e.target.name === "publicPhotoLocation") {
        setFormData({
          name: name ? name : modalInfo.name,
          publicPhotoLocation: e.target.value,
        });
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    nameInput.current.value = "";
    publicPhotoLocationInput.current.value = "";

    if (modalInfo.type === "new") {
      publicPhotoInput.current.value = "";
      previewImage.current.src = "";

      uploadPublicPhoto({
        groupId: modalInfo.groupId,
        userId: user._id,
        name,
        publicPhoto,
        publicPhotoFile,
        publicPhotoLocation,
        date: new Date(),
      });
    } else if (modalInfo.type === "edit") {
      editPublicPhoto({
        groupId: modalInfo.groupId,
        _id: modalInfo._id,
        name,
        publicPhotoLocation,
      });
    }

    setModalInfo({
      groupId: "",
      _id: "",
      name: "",
      publicPhoto: "",
      publicPhotoLocation: "",
      type: "",
      visible: false,
    });
  };

  return (
    <div
      className={classNames("photoFormModal", {
        editPhoto: modalInfo.type === "edit",
      })}
    >
      <button
        className="closePhotoFormModal"
        onClick={() =>
          setModalInfo({
            groupId: "",
            _id: "",
            name: "",
            publicPhoto: "",
            publicPhotoLocation: "",
            type: "",
            visible: false,
          })
        }
      >
        닫기
      </button>
      <form onSubmit={onSubmit}>
        <input
          ref={nameInput}
          type="text"
          name="name"
          onChange={onChange}
          placeholder={user.name}
          aria-label="Name"
          defaultValue={modalInfo.name && modalInfo.name}
          required
        />
        <input
          ref={publicPhotoLocationInput}
          type="text"
          name="publicPhotoLocation"
          onChange={onChange}
          placeholder="사진 설명"
          aria-label="Public Photo Location"
          defaultValue={
            modalInfo.publicPhotoLocation && modalInfo.publicPhotoLocation
          }
        />
        <div className="previewImage">
          <img
            ref={previewImage}
            src={
              modalInfo.type === "new"
                ? "#"
                : `https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/${modalInfo.publicPhoto}`
            }
            alt="Img to upload"
          />
        </div>
        {modalInfo.type === "new" && (
          <input
            ref={publicPhotoInput}
            type="file"
            name="publicPhoto"
            onChange={onChange}
            placeholder="사진"
            aria-label="Public Photo"
            required
          />
        )}
        <button>{modalInfo.type === "new" ? "올리기" : "수정"}</button>
      </form>
    </div>
  );
};

PhotoFormModal.propTypes = {
  user: PropTypes.object,
  modalInfo: PropTypes.object.isRequired,
  uploadPublicPhoto: PropTypes.func.isRequired,
  editPublicPhoto: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  uploadPublicPhoto,
  editPublicPhoto,
})(PhotoFormModal);
