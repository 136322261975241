import React, { useState } from "react";
import "./index.scss";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signIn } from "../../../actions/auth";

const SignInPage = ({ signIn, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    userId: "",
    password: "",
  });
  const { userId, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    signIn({ userId, password });
  };

  if (isAuthenticated) return <Navigate to="/main" />;

  return (
    <div className="signInPage">
      <h1>로그인</h1>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="userId"
          onChange={onChange}
          placeholder="아이디"
          aria-label="User id"
          required
        />
        <input
          type="password"
          name="password"
          onChange={onChange}
          placeholder="비밀번호"
          aria-label="Password"
          required
        />
        <button>로그인</button>
      </form>
      <p>
        <Link to="/signUp">회원가입</Link>
      </p>
    </div>
  );
};

SignInPage.propTypes = {
  signIn: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { signIn })(SignInPage);
