import axios from "axios";
import { setAlert } from "./alerts";
import {
  ACTIVITIES_LOADED,
  ACTIVITIES_LOADED_ERROR,
  MARK_ACTIVITY,
  MARK_ACTIVITY_ERROR,
} from "./types.js";

export const getActivities = () => async (dispatch) => {
  try {
    const res = await axios.get("/activities/getActivities");

    dispatch({
      type: ACTIVITIES_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ACTIVITIES_LOADED_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const markActivity =
  (activity = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(activity);
    try {
      const res = await axios.post("/activities/markActivity", body, config);
      dispatch({
        type: MARK_ACTIVITY,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "activityMarked", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: MARK_ACTIVITY_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      dispatch(setAlert({ msg: "activityNotMarked", alertType: "danger" }));
    }
  };
