import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getRecipes } from "../../../actions/recipes";
import RecipeFormModal from "./RecipeFormModal/index.js";
import { checkRoles } from "../../../utils/library";
import UploadCosmos from "../UploadCosmos";
import classNames from "classnames";

const RecipesPage = ({ user, recipes, getRecipes, isAuthenticated }) => {
  const searchInput = useRef(null);

  const [recipesSearched, setRecipesSearched] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    _id: "",
    name: "",
    recipe: "",
    recipePhoto: "",
    type: "",
    visible: false,
  });

  const searchRecipes = () => {
    const recipeToFind = searchInput.current.value;
    const regex = new RegExp(recipeToFind, "gi");
    const filteredRecipes = recipes.filter((recipe) =>
      recipe.name.match(regex)
    );
    setRecipesSearched(
      filteredRecipes.length === recipes.length ? [] : filteredRecipes
    );
  };

  useEffect(() => {
    getRecipes();
  }, [getRecipes]);

  if (!isAuthenticated) return <Navigate to="/" />;

  return (
    <div className="recipesPage">
      {user && checkRoles(user.roles) && (
        <React.Fragment>
          {modalInfo.visible && (
            <RecipeFormModal
              modalInfo={modalInfo}
              setModalInfo={setModalInfo}
            />
          )}
          {!modalInfo.visible && (
            <React.Fragment>
              <UploadCosmos
                modalInfo={{
                  _id: "",
                  name: "",
                  recipe: "",
                  type: "new",
                  visible: true,
                }}
                setModalInfo={setModalInfo}
              />
              <div className="recipesHeader">
                <p>사랑하는 엄마</p>
              </div>
              <div
                className={classNames("recipes", {
                  showRecipeDetail: recipesSearched.length > 0,
                })}
              >
                <div className="searchedRecipesList">
                  <input
                    type="text"
                    ref={searchInput}
                    onChange={() => searchRecipes()}
                    placeholder="레시피를 검색하세요^^"
                    aria-label="레시피를 검색하세요^^"
                  />
                  {recipesSearched.length > 0 && (
                    <ul>
                      {recipesSearched.map((recipe, i) => (
                        <li key={i}>
                          <Link to={`/recipes/${recipe._id}`}>
                            {recipe.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {recipesSearched.length <= 0 &&
                  recipes.length > 0 &&
                  recipes.map(({ _id, name }, i) => (
                    <div key={i} className="recipeName">
                      <Link to={`/recipes/${_id}`}>{name}</Link>
                    </div>
                  ))}
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

RecipesPage.propTypes = {
  recipes: PropTypes.array,
  getRecipes: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  recipes: state.recipes.recipes,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getRecipes,
})(RecipesPage);
