import {
  ROLES_LOADED,
  ROLES_LOADED_ERROR,
  USERS_LOADED,
  USERS_LOADED_ERROR,
  ROLE_TOGGLED,
  ROLE_TOGGLED_ERROR,
} from "../actions/types";

const initialState = {
  users: [],
  roles: [],
  loading: true,
  error: {},
};

export default function users(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ROLES_LOADED:
      return {
        ...state,
        roles: payload,
        loading: false,
      };
    case USERS_LOADED:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case ROLE_TOGGLED:
      const users = state.users.map((user) => {
        if (user._id === payload._id) {
          return payload;
        } else {
          return user;
        }
      });
      return {
        ...state,
        users,
        loading: false,
      };
    case ROLES_LOADED_ERROR:
    case USERS_LOADED_ERROR:
    case ROLE_TOGGLED_ERROR:
    default:
      return state;
  }
}
