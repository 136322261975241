import React, { useRef, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { writeRecipe, editRecipe } from "../../../../actions/recipes";
import { v4 as uuidv4 } from "uuid";

const RecipeFormModal = ({
  user,
  modalInfo,
  writeRecipe,
  editRecipe,
  setModalInfo,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    recipe: "",
    recipePhoto: "",
    date: "",
  });
  const { name, recipe, recipePhoto, recipePhotoFile } = formData;

  const nameInput = useRef(null);
  const recipeInput = useRef(null);
  const recipePhotoInput = useRef(null);
  const previewImage = useRef(null);

  const onChange = (e) => {
    if (modalInfo.type === "new") {
      if (e.target.files) {
        previewImage.current.src = URL.createObjectURL(e.target.files[0]);

        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          recipePhoto: `${uuidv4()}-recipe-${e.target.files[0].name}`,
          recipePhotoFile: e.target.files[0],
        });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else if (modalInfo.type === "edit") {
      if (e.target.name === "name") {
        setFormData({
          name: e.target.value,
          recipe: recipe ? recipe : modalInfo.recipe,
        });
      } else if (e.target.name === "recipe") {
        setFormData({
          name: name ? name : modalInfo.name,
          recipe: e.target.value,
        });
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    nameInput.current.value = "";
    recipeInput.current.value = "";

    if (modalInfo.type === "new") {
      recipePhotoInput.current.value = "";
      previewImage.current.src = "";

      writeRecipe({
        userId: user._id,
        name,
        recipe,
        recipePhoto,
        recipePhotoFile,
        date: new Date(),
      });
    } else if (modalInfo.type === "edit") {
      editRecipe({ _id: modalInfo._id, name, recipe });
    }
    setModalInfo({
      _id: "",
      name: "",
      recipe: "",
      type: "",
      visible: false,
    });
  };

  return (
    <div className="recipeFormModal">
      <button
        className="closeRecipeFormModal"
        onClick={() =>
          setModalInfo({
            _id: "",
            name: "",
            recipe: "",
            type: "",
            visible: false,
          })
        }
      >
        닫기
      </button>
      <form onSubmit={onSubmit}>
        <input
          ref={nameInput}
          type="text"
          name="name"
          onChange={onChange}
          placeholder={user.name}
          aria-label="Name"
          defaultValue={modalInfo.name && modalInfo.name}
          required
        />
        <div className="previewImage">
          <img
            ref={previewImage}
            src={
              modalInfo.type === "new"
                ? "#"
                : `https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/${modalInfo.recipePhoto}`
            }
            alt="Img to upload"
          />
        </div>
        {modalInfo.type === "new" && (
          <input
            ref={recipePhotoInput}
            type="file"
            name="recipePhoto"
            onChange={onChange}
            placeholder="레시피"
            aria-label="Recipe"
            required
          />
        )}
        <textarea
          ref={recipeInput}
          name="recipe"
          onChange={onChange}
          placeholder="레시피 설명^^"
          aria-label="Recipe"
          defaultValue={modalInfo.recipe && modalInfo.recipe}
          required
        />
        <button>{modalInfo.type === "new" ? "보내기" : "수정"}</button>
      </form>
    </div>
  );
};

RecipeFormModal.propTypes = {
  modalInfo: PropTypes.object.isRequired,
  writeRecipe: PropTypes.func.isRequired,
  editRecipe: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  writeRecipe,
  editRecipe,
})(RecipeFormModal);
