import React, { useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import VideoFormModal from "../VideoFormModal/index.js";
import UploadCosmos from "../../UploadCosmos";
import { deletePublicVideo } from "../../../../actions/publicVideos";
import classNames from "classnames";

const GroupedVideos = ({ user, publicVideos, deletePublicVideo }) => {
  const { groupId } = useParams();

  const [modalInfo, setModalInfo] = useState({
    groupId: "",
    _id: "",
    name: "",
    publicVideo: "",
    publicVideoLocation: "",
    type: "",
    visible: false,
  });

  const confirmDeletePublicVideo = (_id, name, publicVideo) => {
    if (window.confirm("동영상을 지우시겠습니까?")) {
      const prompt = window.prompt(`다음을 입력하시오: ${name}`);
      if (prompt === name) {
        deletePublicVideo({ groupId, _id, publicVideo });
      } else {
        window.alert("잘못 입력 하였습니다");
      }
    }
  };

  return (
    <React.Fragment>
      <Link to="/videos" className="back">
        뒤로
      </Link>
      {!modalInfo.visible && (
        <UploadCosmos
          modalInfo={{
            groupId,
            _id: "",
            name: "",
            publicVideo: "",
            publicVideoLocation: "",
            type: "new",
            visible: true,
          }}
          setModalInfo={setModalInfo}
        />
      )}
      {modalInfo.visible && (
        <VideoFormModal setModalInfo={setModalInfo} modalInfo={modalInfo} />
      )}
      <div className="groupedVideos">
        {!modalInfo.visible &&
          publicVideos.length &&
          publicVideos
            .find(({ _id }) => _id === groupId)
            ?.groupedPublicVideos?.sort((a, b) => {
              if (a.date < b.date) {
                return 1;
              }
              if (a.date > b.date) {
                return -1;
              }
              return 0;
            })
            .map(
              (
                { _id, userId, name, publicVideo, publicVideoLocation, date },
                i
              ) => (
                <div
                  key={i}
                  className={classNames("video", {
                    videoOwner: userId === user._id,
                  })}
                >
                  {userId === user._id && (
                    <div className="buttonContainer">
                      <button
                        onClick={() =>
                          setModalInfo({
                            groupId,
                            _id,
                            name,
                            publicVideo,
                            publicVideoLocation,
                            type: "edit",
                            visible: true,
                          })
                        }
                      >
                        수정
                      </button>
                      <button
                        onClick={() =>
                          confirmDeletePublicVideo(_id, name, publicVideo)
                        }
                      >
                        X
                      </button>
                    </div>
                  )}
                  <p>{name}</p>
                  <div>
                    <video
                      key={`https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/${publicVideo}`}
                      controls
                    >
                      <source
                        src={`https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/${publicVideo}#t=0.001`}
                      />
                    </video>
                  </div>
                  {publicVideoLocation && <p>{publicVideoLocation}</p>}
                </div>
              )
            )}
      </div>
    </React.Fragment>
  );
};

GroupedVideos.propTypes = {
  user: PropTypes.object,
  publicVideos: PropTypes.array,
  deletePublicVideo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  publicVideos: state.publicVideos.publicVideos,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  deletePublicVideo,
})(GroupedVideos);
