import React, { useRef, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  uploadPublicVideo,
  editPublicVideo,
} from "../../../../actions/publicVideos";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";

const VideoFormModal = ({
  user,
  modalInfo,
  uploadPublicVideo,
  editPublicVideo,
  setModalInfo,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    publicVideo: "",
    publicVideoFile: "",
    publicVideoLocation: "",
  });
  const { name, publicVideo, publicVideoFile, publicVideoLocation } = formData;

  const nameInput = useRef(null);
  const publicVideoInput = useRef(null);
  const publicVideoLocationInput = useRef(null);
  const previewVideo = useRef(null);

  const onChange = (e) => {
    if (modalInfo.type === "new") {
      if (e.target.files) {
        previewVideo.current.src = URL.createObjectURL(e.target.files[0]);
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          publicVideo: `${uuidv4()}-publicVideo-${e.target.files[0].name}`,
          publicVideoFile: e.target.files[0],
        });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else if (modalInfo.type === "edit") {
      if (e.target.name === "name") {
        setFormData({
          name: e.target.value,
          publicVideoLocation: publicVideoLocation
            ? publicVideoLocation
            : modalInfo.publicVideoLocation,
        });
      } else if (e.target.name === "publicVideoLocation") {
        setFormData({
          name: name ? name : modalInfo.name,
          publicVideoLocation: e.target.value,
        });
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    nameInput.current.value = "";
    publicVideoLocationInput.current.value = "";

    if (modalInfo.type === "new") {
      publicVideoInput.current.value = "";
      previewVideo.current.src = "";

      uploadPublicVideo({
        groupId: modalInfo.groupId,
        userId: user._id,
        name,
        publicVideo,
        publicVideoFile,
        publicVideoLocation,
        date: new Date(),
      });
    } else if (modalInfo.type === "edit") {
      editPublicVideo({
        groupId: modalInfo.groupId,
        _id: modalInfo._id,
        name,
        publicVideoLocation,
      });
    }

    setModalInfo({
      groupId: "",
      _id: "",
      name: "",
      publicVideo: "",
      publicVideoLocation: "",
      type: "",
      visible: false,
    });
  };

  return (
    <div
      className={classNames("videoFormModal", {
        editVideo: modalInfo.type === "edit",
      })}
    >
      <button
        className="closeVideoFormModal"
        onClick={() =>
          setModalInfo({
            groupId: "",
            _id: "",
            name: "",
            publicVideo: "",
            publicVideoLocation: "",
            type: "",
            visible: false,
          })
        }
      >
        닫기
      </button>
      <form onSubmit={onSubmit}>
        <input
          ref={nameInput}
          type="text"
          name="name"
          onChange={onChange}
          placeholder={user.name}
          aria-label="Name"
          defaultValue={modalInfo.name && modalInfo.name}
          required
        />
        <input
          ref={publicVideoLocationInput}
          type="text"
          name="publicVideoLocation"
          onChange={onChange}
          placeholder="동영상 설명"
          aria-label="Public Video Location"
          defaultValue={
            modalInfo.publicVideoLocation && modalInfo.publicVideoLocation
          }
        />
        <div className="previewVideo">
          <video ref={previewVideo} controls>
            <source
              src={
                modalInfo.type === "new"
                  ? "#"
                  : `https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/${modalInfo.publicVideo}#t=0.001`
              }
            />
          </video>
        </div>
        {modalInfo.type === "new" && (
          <input
            ref={publicVideoInput}
            type="file"
            name="publicVideo"
            onChange={onChange}
            placeholder="동영상"
            aria-label="Public Video"
            required
          />
        )}
        <button>{modalInfo.type === "new" ? "올리기" : "수정"}</button>
      </form>
    </div>
  );
};

VideoFormModal.propTypes = {
  user: PropTypes.object,
  modalInfo: PropTypes.object.isRequired,
  uploadPublicVideo: PropTypes.func.isRequired,
  editPublicVideo: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  uploadPublicVideo,
  editPublicVideo,
})(VideoFormModal);
