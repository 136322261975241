import React, { useRef, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createPhotoGroup } from "../../../../actions/publicPhotos";

const GroupPhotoFormModal = ({ user, createPhotoGroup, setModalVisible }) => {
  const [formData, setFormData] = useState({
    publicPhotoGroupName: "",
  });
  const { publicPhotoGroupName } = formData;

  const publicPhotoGroupNameInput = useRef(null);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    publicPhotoGroupNameInput.current.value = "";
    createPhotoGroup({
      userId: user._id,
      name: user.name ? user.name : "이름",
      publicPhotoGroupName,
      date: new Date(),
    });
    setModalVisible(false);
  };

  return (
    <div className="groupPhotoFormModal">
      <button
        className="closeGroupPhotoFormModal"
        onClick={() => setModalVisible(false)}
      >
        닫기
      </button>
      <form onSubmit={onSubmit}>
        <input
          ref={publicPhotoGroupNameInput}
          type="text"
          name="publicPhotoGroupName"
          onChange={onChange}
          placeholder="사진 그룹 이름"
          aria-label="Public photo group"
          required
        />
        <button>그룹 만들기</button>
      </form>
    </div>
  );
};

GroupPhotoFormModal.propTypes = {
  createPhotoGroup: PropTypes.func.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  createPhotoGroup,
})(GroupPhotoFormModal);
