import { ACTIVITIES_LOADED, ACTIVITIES_LOADED_ERROR } from "../actions/types";

const initialState = {
  activities: [],
  loading: true,
  error: {},
};

export default function activities(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ACTIVITIES_LOADED:
      return {
        ...state,
        activities: payload,
        loading: false,
      };
    case ACTIVITIES_LOADED_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
