import {
  RECIPES_LOADED,
  RECIPES_LOADED_ERROR,
  WRITE_RECIPE,
  WRITE_RECIPE_ERROR,
  EDIT_RECIPE,
  EDIT_RECIPE_ERROR,
} from "../actions/types";

const initialState = {
  recipes: [],
  loading: true,
  error: {},
};

export default function recipes(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RECIPES_LOADED:
      return {
        ...state,
        recipes: payload,
        loading: false,
      };
    case WRITE_RECIPE:
      return {
        ...state,
        recipes: [payload, ...state.recipes],
        loading: false,
      };
    case EDIT_RECIPE:
      const recipes = state.recipes.map((recipe) => {
        if (recipe._id === payload._id) {
          return payload;
        } else {
          return recipe;
        }
      });
      return {
        ...state,
        recipes,
        loading: false,
      };
    case RECIPES_LOADED_ERROR:
    case WRITE_RECIPE_ERROR:
    case EDIT_RECIPE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
