import React, { useRef, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  writePublicLetter,
  editPublicLetter,
} from "../../../../actions/publicLetters";

const LetterFormModal = ({
  user,
  modalInfo,
  writePublicLetter,
  editPublicLetter,
  setModalInfo,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    publicLetter: "",
    date: "",
  });
  const { name, publicLetter } = formData;

  const nameInput = useRef(null);
  const publicLetterInput = useRef(null);

  const onChange = (e) => {
    if (modalInfo.type === "new") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else if (modalInfo.type === "edit") {
      if (e.target.name === "name") {
        setFormData({
          name: e.target.value,
          publicLetter: publicLetter ? publicLetter : modalInfo.publicLetter,
        });
      } else if (e.target.name === "publicLetter") {
        setFormData({
          name: name ? name : modalInfo.name,
          publicLetter: e.target.value,
        });
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    nameInput.current.value = "";
    publicLetterInput.current.value = "";
    if (modalInfo.type === "new") {
      writePublicLetter({
        userId: user._id,
        name,
        publicLetter,
        date: new Date(),
      });
    } else if (modalInfo.type === "edit") {
      editPublicLetter({ _id: modalInfo._id, name, publicLetter });
    }
    setModalInfo({
      _id: "",
      name: "",
      publicLetter: "",
      type: "",
      visible: false,
    });
  };

  return (
    <div className="letterFormModal">
      <button
        className="closeLetterFormModal"
        onClick={() =>
          setModalInfo({
            _id: "",
            name: "",
            publicLetter: "",
            type: "",
            visible: false,
          })
        }
      >
        닫기
      </button>
      <form onSubmit={onSubmit}>
        <input
          ref={nameInput}
          type="text"
          name="name"
          onChange={onChange}
          placeholder={user.name}
          aria-label="Name"
          defaultValue={modalInfo.name && modalInfo.name}
          required
        />
        <textarea
          ref={publicLetterInput}
          name="publicLetter"
          onChange={onChange}
          placeholder="엄마^^"
          aria-label="Public Letter"
          defaultValue={modalInfo.publicLetter && modalInfo.publicLetter}
          required
        />
        <button>{modalInfo.type === "new" ? "보내기" : "수정"}</button>
      </form>
    </div>
  );
};

LetterFormModal.propTypes = {
  modalInfo: PropTypes.object.isRequired,
  writePublicLetter: PropTypes.func.isRequired,
  editPublicLetter: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  writePublicLetter,
  editPublicLetter,
})(LetterFormModal);
