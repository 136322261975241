import axios from "axios";
import { setAlert } from "./alerts";
import {
  RECIPES_LOADED,
  RECIPES_LOADED_ERROR,
  WRITE_RECIPE,
  WRITE_RECIPE_ERROR,
  EDIT_RECIPE,
  EDIT_RECIPE_ERROR,
} from "./types.js";
import { uploadFileToS3 } from "../utils/s3uploader";

export const getRecipes = () => async (dispatch) => {
  try {
    const res = await axios.get("/recipes/getRecipes");

    dispatch({
      type: RECIPES_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RECIPES_LOADED_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const writeRecipe =
  (recipe = {}) =>
  async (dispatch) => {
    await uploadFileToS3({
      key: recipe.recipePhoto,
      file: recipe.recipePhotoFile,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(recipe);
    try {
      const res = await axios.post("/recipes/writeRecipe", body, config);

      dispatch({
        type: WRITE_RECIPE,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "recipeWritten", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: WRITE_RECIPE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      dispatch(setAlert({ msg: "recipeNotWritten", alertType: "danger" }));
    }
  };

export const editRecipe =
  (recipe = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(recipe);

    try {
      const res = await axios.put("/recipes/editRecipe", body, config);

      dispatch({
        type: EDIT_RECIPE,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "recipeEdited", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: EDIT_RECIPE_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
      dispatch(setAlert({ msg: "recipeNotEdited", alertType: "danger" }));
    }
  };
