export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const SIGN_OUT = "SIGN_OUT";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const AUTH_ERROR = "AUTH_ERROR";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const ROLES_LOADED = "ROLES_LOADED";
export const ROLES_LOADED_ERROR = "ROLES_LOADED_ERROR";
export const USERS_LOADED = "USERS_LOADED";
export const USERS_LOADED_ERROR = "USERS_LOADED_ERROR";
export const ROLE_TOGGLED = "ROLE_TOGGLED";
export const ROLE_TOGGLED_ERROR = "ROLE_TOGGLED_ERROR";
export const WRITE_PUBLIC_LETTER = "WRITE_PUBLIC_LETTER";
export const WRITE_PUBLIC_LETTER_ERROR = "WRITE_PUBLIC_LETTER_ERROR";
export const EDIT_PUBLIC_LETTER = "EDIT_PUBLIC_LETTER";
export const EDIT_PUBLIC_LETTER_ERROR = "EDIT_PUBLIC_LETTER_ERROR";
export const PUBLIC_LETTERS_LOADED = "PUBLIC_LETTERS_LOADED";
export const PUBLIC_LETTERS_LOADED_ERROR = "PUBLIC_LETTERS_LOADED_ERROR";
export const DELETE_PUBLIC_LETTER = "DELETE_PUBLIC_LETTER";
export const DELETE_PUBLIC_LETTER_ERROR = "DELETE_PUBLIC_LETTER_ERROR";
export const PUBLIC_PHOTOS_LOADED = "PUBLIC_PHOTOS_LOADED";
export const PUBLIC_PHOTOS_LOADED_ERROR = "PUBLIC_PHOTOS_LOADED_ERROR";
export const CREATE_PHOTO_GROUP = "CREATE_PHOTO_GROUP";
export const CREATE_PHOTO_GROUP_ERROR = "CREATE_PHOTO_GROUP_ERROR";
export const UPLOAD_PUBLIC_PHOTO = "UPLOAD_PUBLIC_PHOTO";
export const UPLOAD_PUBLIC_PHOTO_ERROR = "UPLOAD_PUBLIC_PHOTO_ERROR";
export const EDIT_PUBLIC_PHOTO = "EDIT_PUBLIC_PHOTO";
export const EDIT_PUBLIC_PHOTO_ERROR = "EDIT_PUBLIC_PHOTO_ERROR";
export const DELETE_PUBLIC_PHOTO = "DELETE_PUBLIC_PHOTO";
export const DELETE_PUBLIC_PHOTO_ERROR = "DELETE_PUBLIC_PHOTO_ERROR";
export const PUBLIC_VIDEOS_LOADED = "PUBLIC_VIDEOS_LOADED";
export const PUBLIC_VIDEOS_LOADED_ERROR = "PUBLIC_VIDEOS_LOADED_ERROR";
export const CREATE_VIDEO_GROUP = "CREATE_VIDEO_GROUP";
export const CREATE_VIDEO_GROUP_ERROR = "CREATE_VIDEO_GROUP_ERROR";
export const UPLOAD_PUBLIC_VIDEO = "UPLOAD_PUBLIC_VIDEO";
export const UPLOAD_PUBLIC_VIDEO_ERROR = "UPLOAD_PUBLIC_VIDEO_ERROR";
export const EDIT_PUBLIC_VIDEO = "EDIT_PUBLIC_VIDEO";
export const EDIT_PUBLIC_VIDEO_ERROR = "EDIT_PUBLIC_VIDEO_ERROR";
export const DELETE_PUBLIC_VIDEO = "DELETE_PUBLIC_VIDEO";
export const DELETE_PUBLIC_VIDEO_ERROR = "DELETE_PUBLIC_VIDEO_ERROR";
export const RECIPES_LOADED = "RECIPES_LOADED";
export const RECIPES_LOADED_ERROR = "RECIPES_LOADED_ERROR";
export const WRITE_RECIPE = "WRITE_RECIPE";
export const WRITE_RECIPE_ERROR = "WRITE_RECIPE_ERROR";
export const EDIT_RECIPE = "EDIT_RECIPE";
export const EDIT_RECIPE_ERROR = "EDIT_RECIPE_ERROR";
export const MARK_ACTIVITY = "MARK_ACTIVITY";
export const MARK_ACTIVITY_ERROR = "MARK_ACTIVITY_ERROR";
export const ACTIVITIES_LOADED = "ACTIVITIES_LOADED";
export const ACTIVITIES_LOADED_ERROR = "ACTIVITIES_LOADED_ERROR";
export const SONGS_LOADED = "SONGS_LOADED";
export const SONGS_LOADED_ERROR = "SONGS_LOADED_ERROR";
export const WRITE_SONG = "WRITE_SONG";
export const WRITE_SONG_ERROR = "WRITE_SONG_ERROR";
export const EDIT_SONG = "EDIT_SONG";
export const EDIT_SONG_ERROR = "EDIT_SONG_ERROR";
