import S3 from "aws-sdk/clients/s3";
import axios from "axios";

const s3 = new S3({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_REGION,
});

export const uploadFileToS3 = async ({ key, file }) => {
  const signedUrl = await s3.getSignedUrl("putObject", {
    Bucket: process.env.REACT_APP_S3_BUCKET,
    Key: key,
  });
  await axios.put(signedUrl, file, {
    headers: { "Content-Type": file.type },
    onUploadProgress(event) {
      // console.log("event", event);
      if (event.progress === 1) {
        // console.log("uploaded");
      }
    },
  });
  return signedUrl;
};

export const uploadPublicVideoToS3 = async ({
  publicVideo,
  publicVideoFile,
}) => {
  const signedUrl = await s3.getSignedUrl("putObject", {
    Bucket: process.env.REACT_APP_S3_BUCKET,
    Key: publicVideo,
  });
  await axios.put(signedUrl, publicVideoFile, {
    headers: { "Content-Type": publicVideoFile.type },
    onUploadProgress(event) {
      // console.log("event", event);
      if (event.progress === 1) {
        // console.log("uploaded");
      }
    },
  });
  return signedUrl;
};

export const deletePublicVideoToS3 = async (publicVideo) => {
  const signedUrl = await s3.deleteObject(
    {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: publicVideo,
    },
    (err, data) => {
      if (err) {
        // console.log(err, err.stack);
      } else {
        // console.log(data);
      }
    }
  );
  return signedUrl;
};
