import React, { useState, useEffect } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { getSongs } from "../../../actions/songs";
import SongFormModal from "./SongFormModal/index.js";
import { checkRoles } from "../../../utils/library";
import UploadCosmos from "../UploadCosmos";

const SongsPage = ({ user, songs, getSongs, isAuthenticated }) => {
  const [modalInfo, setModalInfo] = useState({
    _id: "",
    name: "",
    songTitle: "",
    song: "",
    songDescription: "",
    type: "",
    visible: false,
  });
  const [songToRepeat, setSongToRepeat] = useState(null);
  const [allSongsRepeating, setAllSongsRepeating] = useState(false);
  const [repeatingSong, setRepeatingSong] = useState(null);

  const repeatSong = (i) => {
    const song = document.getElementById(`song-${i}`);

    if (songToRepeat) {
      songToRepeat.loop = false;
      songToRepeat.pause();
      songToRepeat.currentTime = 0;

      if (songToRepeat.id === song.id) {
        setSongToRepeat(null);
        return;
      }
    }

    song.loop = true;
    song.play();
    setSongToRepeat(song);

    if (repeatingSong) {
      repeatingSong.pause();
      setRepeatingSong(null);
      setAllSongsRepeating(false);
    }
  };

  const repeatAllSongs = (e) => {
    if (songToRepeat) {
      songToRepeat.loop = false;
      songToRepeat.pause();
      songToRepeat.currentTime = 0;
      setSongToRepeat(null);
    }
    setAllSongsRepeating(!allSongsRepeating);

    const repeat = e.target.textContent === "전체 반복";
    const allSongsUrl = songs.map(({ song }) => song);
    const baseUrl =
      "https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/";
    const songToPlay = new Audio(`${baseUrl}${allSongsUrl[0]}`);

    if (repeat) {
      setRepeatingSong(songToPlay);
      songToPlay.play();
      let index = 0;
      songToPlay.addEventListener("ended", () => {
        index++;
        if (index === allSongsUrl.length) {
          index = 0;
        }
        songToPlay.src = `${baseUrl}${allSongsUrl[index]}`;
        songToPlay.play();
        setRepeatingSong(songToPlay);
      });
    } else {
      repeatingSong.pause();
      setRepeatingSong(null);
    }
  };

  useEffect(() => {
    getSongs();
  }, [getSongs]);

  if (!isAuthenticated) return <Navigate to="/" />;

  return (
    <div className="songsPage">
      {user && checkRoles(user.roles) && (
        <React.Fragment>
          <UploadCosmos
            modalInfo={{
              _id: "",
              name: "",
              songTitle: "",
              song: "",
              songDescription: "",
              type: "new",
              visible: !modalInfo.visible,
            }}
            setModalInfo={setModalInfo}
          />
          {modalInfo.visible && (
            <SongFormModal modalInfo={modalInfo} setModalInfo={setModalInfo} />
          )}
          <div className="songsHeader">
            <p>사랑하는 엄마</p>
          </div>
          <button className="repeatAllSongs" onClick={(e) => repeatAllSongs(e)}>
            {allSongsRepeating ? "반복 중" : "전체 반복"}
          </button>
          {!modalInfo.visible && (
            <div className="songs">
              {songs.length > 0 &&
                songs.map(
                  (
                    { _id, userId, name, songTitle, song, songDescription },
                    i
                  ) => (
                    <div key={i} className="song">
                      <div className="buttonContainer">
                        {userId === user._id && (
                          <button
                            onClick={() =>
                              setModalInfo({
                                _id,
                                name,
                                songTitle,
                                song,
                                songDescription,
                                type: "edit",
                                visible: !modalInfo.visible,
                              })
                            }
                          >
                            수정
                          </button>
                        )}
                        <button onClick={() => repeatSong(i)}>
                          {songToRepeat?.id === `song-${i}`
                            ? "반복 중"
                            : "반복 하기"}
                        </button>
                      </div>
                      <p>{name}</p>
                      <p>{songTitle}</p>
                      <audio id={`song-${i}`} controls>
                        <source
                          src={`https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/${song}`}
                        ></source>
                      </audio>
                      <p>{songDescription}</p>
                    </div>
                  )
                )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

SongsPage.propTypes = {
  songs: PropTypes.array,
  getSongs: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  songs: state.songs.songs,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getSongs,
})(SongsPage);
