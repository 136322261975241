import React, { useState } from "react";
import "./index.scss";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signUp } from "../../../actions/auth";

const SignUpPage = ({ signUp, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    userId: "",
    name: "",
    password: "",
    passwordConfirmed: "",
  });
  const { userId, name, password, passwordConfirmed } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (password.length < 3) {
      alert("비밀번호는 3글자 이상이어야 합니다");
      return;
    }

    if (password === passwordConfirmed) {
      signUp({ userId, name, password });
    } else {
      alert("비밀번호가 일치하지 않습니다");
    }
  };

  if (isAuthenticated) return <Navigate to="/main" />;

  return (
    <div className="signUpPage">
      <h1>회원가입</h1>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="userId"
          onChange={onChange}
          placeholder="아이디"
          aria-label="User id"
          required
        />
        <input
          type="password"
          name="password"
          onChange={onChange}
          placeholder="비밀번호"
          aria-label="Password"
          required
        />
        <input
          type="password"
          name="passwordConfirmed"
          onChange={onChange}
          placeholder="비밀번호를 다시 입력하여 주십시오"
          aria-label="Confirm password"
          required
        />
        <input
          type="text"
          name="name"
          onChange={onChange}
          placeholder="닉네임"
          aria-label="User name"
          required
        />
        <button>회원가입</button>
      </form>
      <p>
        이미 계정이 있으신가요?
        <br />
        <Link to="/">로그인 하러가기</Link>
      </p>
    </div>
  );
};

SignUpPage.propTypes = {
  signUp: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { signUp })(SignUpPage);
