import React, { useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import { markActivity } from "../../../actions/activities";
import moment from "moment";
import "moment/locale/ko";

const Menu = ({ isAuthenticated, user, markActivity }) => {
  const [showList, setShowList] = useState(false);

  const getLinks = () => {
    const pages = [
      { name: "홈", link: "/" },
      { name: "사진", link: "/photos" },
      { name: "동영상", link: "/videos" },
      { name: "편지", link: "/letters" },
      { name: "레시피", link: "/recipes" },
      { name: "노래", link: "/songs" },
      { name: "접근", link: "/access" },
      { name: "활동", link: "/activities" },
    ];

    return pages
      .filter((page) => {
        const 창모 = user && user.roles.includes("창모");
        if (!창모 && (page.name === "활동" || page.name === "접근")) {
          return false;
        } else {
          return true;
        }
      })
      .map((page, i) => (
        <li key={i}>
          <Link
            to={`${page.link}`}
            onClick={() => {
              setShowList(!showList);
              if (user && !user.roles.includes("창모")) {
                const now = new Date();
                markActivity({
                  name: user.name,
                  userId: user._id,
                  location: page.name,
                  date: now,
                  today: `${moment(now).format("LL")}`,
                });
              }
            }}
          >
            {page.name}
          </Link>
        </li>
      ));
  };

  return (
    <div className={classNames("menu", { showMenu: isAuthenticated })}>
      <button onClick={() => setShowList(!showList)}>메뉴</button>
      <ul className={classNames({ show: showList })}>{getLinks()}</ul>
    </div>
  );
};

Menu.propTypes = {
  isAuthenticated: PropTypes.bool,
  markActivity: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  markActivity,
})(Menu);
