import React, { useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import PhotoFormModal from "../PhotoFormModal/index.js";
import PhotoEnlarged from "../../PhotoEnlarged/index.js";
import UploadCosmos from "../../UploadCosmos";
import { deletePublicPhoto } from "../../../../actions/publicPhotos";
import classNames from "classnames";

const GroupedPhotos = ({ user, publicPhotos, deletePublicPhoto }) => {
  const { groupId } = useParams();

  const [modalInfo, setModalInfo] = useState({
    groupId: "",
    _id: "",
    name: "",
    publicPhoto: "",
    publicPhotoLocation: "",
    type: "",
    visible: false,
  });
  const [photoEnlarged, setPhotoEnlarged] = useState({
    photoEnlarged: "",
    active: false,
  });

  const confirmDeletePublicPhoto = (_id, name) => {
    if (window.confirm("사진을 지우시겠습니까?")) {
      const prompt = window.prompt(`다음을 입력하시오: ${name}`);
      if (prompt === name) {
        deletePublicPhoto({ groupId, _id });
      } else {
        window.alert("잘못 입력 하였습니다");
      }
    }
  };

  const getGroupedPublicPhotos = () =>
    publicPhotos
      .find(({ _id }) => _id === groupId)
      ?.groupedPublicPhotos?.sort((a, b) => {
        if (a.date < b.date) {
          return 1;
        }
        if (a.date > b.date) {
          return -1;
        }
        return 0;
      });

  return (
    <React.Fragment>
      <Link to="/photos" className="back">
        뒤로
      </Link>
      {!photoEnlarged.active && !modalInfo.visible && (
        <UploadCosmos
          modalInfo={{
            groupId,
            _id: "",
            name: "",
            publicPhoto: "",
            publicPhotoLocation: "",
            type: "new",
            visible: true,
          }}
          setModalInfo={setModalInfo}
        />
      )}
      {modalInfo.visible && !photoEnlarged.active && (
        <PhotoFormModal setModalInfo={setModalInfo} modalInfo={modalInfo} />
      )}
      <div className="groupedPhotos">
        {!modalInfo.visible &&
          publicPhotos.length &&
          getGroupedPublicPhotos().map(
            ({ _id, userId, name, publicPhoto, publicPhotoLocation }, i) => (
              <div
                key={i}
                className={classNames("photo", {
                  photoOwner: userId === user._id,
                })}
              >
                {userId === user._id && (
                  <div className="buttonContainer">
                    <button
                      onClick={() =>
                        setModalInfo({
                          groupId,
                          _id,
                          name,
                          publicPhoto,
                          publicPhotoLocation,
                          type: "edit",
                          visible: true,
                        })
                      }
                    >
                      수정
                    </button>
                    <button onClick={() => confirmDeletePublicPhoto(_id, name)}>
                      X
                    </button>
                  </div>
                )}
                <p>{name}</p>
                <div
                  onClick={() => {
                    setPhotoEnlarged({
                      photoEnlarged: publicPhoto,
                      active: true,
                    });
                    document.querySelector("body").style.overflow = "hidden";
                  }}
                >
                  <img
                    src={`https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/${publicPhoto}`}
                    alt="Publicly uploaded"
                  />
                </div>
                {publicPhotoLocation && <p>{publicPhotoLocation}</p>}
              </div>
            )
          )}
      </div>
      {!modalInfo.visible && photoEnlarged.active && (
        <PhotoEnlarged
          photoEnlarged={photoEnlarged}
          setPhotoEnlarged={setPhotoEnlarged}
          groupedPhotos={getGroupedPublicPhotos()}
        />
      )}
    </React.Fragment>
  );
};

GroupedPhotos.propTypes = {
  user: PropTypes.object,
  publicPhotos: PropTypes.array,
  deletePublicPhoto: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  publicPhotos: state.publicPhotos.publicPhotos,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  deletePublicPhoto,
})(GroupedPhotos);
