import axios from "axios";
import { setAlert } from "./alerts";
import {
  PUBLIC_LETTERS_LOADED,
  PUBLIC_LETTERS_LOADED_ERROR,
  WRITE_PUBLIC_LETTER,
  WRITE_PUBLIC_LETTER_ERROR,
  EDIT_PUBLIC_LETTER,
  EDIT_PUBLIC_LETTER_ERROR,
  DELETE_PUBLIC_LETTER,
  DELETE_PUBLIC_LETTER_ERROR,
} from "./types.js";

export const getPublicLetters = () => async (dispatch) => {
  try {
    const res = await axios.get("/publicLetters/getPublicLetters");

    dispatch({
      type: PUBLIC_LETTERS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PUBLIC_LETTERS_LOADED_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const writePublicLetter =
  (publicLetter = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(publicLetter);
    try {
      const res = await axios.post(
        "/publicLetters/writePublicLetter",
        body,
        config
      );

      dispatch({
        type: WRITE_PUBLIC_LETTER,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "letterWritten", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: WRITE_PUBLIC_LETTER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      dispatch(setAlert({ msg: "letterNotWritten", alertType: "danger" }));
    }
  };

export const editPublicLetter =
  (publicLetter = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(publicLetter);

    try {
      const res = await axios.put(
        "/publicLetters/editPublicLetter",
        body,
        config
      );

      dispatch({
        type: EDIT_PUBLIC_LETTER,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "letterEdited", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: EDIT_PUBLIC_LETTER_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
      dispatch(setAlert({ msg: "letterNotEdited", alertType: "danger" }));
    }
  };

export const deletePublicLetter =
  (publicLetterId = "") =>
  async (dispatch) => {
    try {
      const res = await axios.delete(
        `/publicLetters/deletePublicLetter/${publicLetterId}`
      );

      dispatch({
        type: DELETE_PUBLIC_LETTER,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "letterDeleted", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: DELETE_PUBLIC_LETTER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      dispatch(setAlert({ msg: "letterNotDeletec", alertType: "danger" }));
    }
  };
