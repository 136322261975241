import React, { useRef, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createVideoGroup } from "../../../../actions/publicVideos";

const GroupVideoFormModal = ({ user, createVideoGroup, setModalVisible }) => {
  const [formData, setFormData] = useState({
    publicVideoGroupName: "",
  });
  const { publicVideoGroupName } = formData;

  const publicVideoGroupNameInput = useRef(null);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    publicVideoGroupNameInput.current.value = "";
    createVideoGroup({
      userId: user._id,
      name: user.name ? user.name : "이름",
      publicVideoGroupName,
      date: new Date(),
    });
    setModalVisible(false);
  };

  return (
    <div className="groupVideoFormModal">
      <button
        className="closeGroupVideoFormModal"
        onClick={() => setModalVisible(false)}
      >
        닫기
      </button>
      <form onSubmit={onSubmit}>
        <input
          ref={publicVideoGroupNameInput}
          type="text"
          name="publicVideoGroupName"
          onChange={onChange}
          placeholder="동영상 그룹 이름"
          aria-label="Public video group"
          required
        />
        <button>그룹 만들기</button>
      </form>
    </div>
  );
};

GroupVideoFormModal.propTypes = {
  createVideoGroup: PropTypes.func.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  createVideoGroup,
})(GroupVideoFormModal);
