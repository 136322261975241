import {
  PUBLIC_VIDEOS_LOADED,
  PUBLIC_VIDEOS_LOADED_ERROR,
  CREATE_VIDEO_GROUP,
  CREATE_VIDEO_GROUP_ERROR,
  UPLOAD_PUBLIC_VIDEO,
  UPLOAD_PUBLIC_VIDEO_ERROR,
  EDIT_PUBLIC_VIDEO,
  EDIT_PUBLIC_VIDEO_ERROR,
  DELETE_PUBLIC_VIDEO,
  DELETE_PUBLIC_VIDEO_ERROR,
} from "../actions/types";

const initialState = {
  publicVideos: [],
  loading: true,
  error: {},
};

export default function publicVideos(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PUBLIC_VIDEOS_LOADED:
      return {
        ...state,
        publicVideos: payload,
        loading: false,
      };
    case CREATE_VIDEO_GROUP:
      return {
        ...state,
        publicVideos: [...state.publicVideos, payload],
        loading: false,
      };
    case UPLOAD_PUBLIC_VIDEO:
    case DELETE_PUBLIC_VIDEO:
      const publicVideos = state.publicVideos.map((publicVideo) => {
        if (publicVideo._id === payload._id) {
          return payload;
        } else {
          return publicVideo;
        }
      });
      return {
        ...state,
        publicVideos,
        loading: false,
      };
    case EDIT_PUBLIC_VIDEO:
      const editedPublicVideos = state.publicVideos.map((publicVideo) => {
        if (publicVideo._id === payload._id) {
          return payload;
        } else {
          return publicVideo;
        }
      });
      return {
        ...state,
        publicVideos: editedPublicVideos,
        loading: false,
      };
    case PUBLIC_VIDEOS_LOADED_ERROR:
    case CREATE_VIDEO_GROUP_ERROR:
    case UPLOAD_PUBLIC_VIDEO_ERROR:
    case DELETE_PUBLIC_VIDEO_ERROR:
    case EDIT_PUBLIC_VIDEO_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
