import React, { useState, useEffect } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getPublicVideos } from "../../../actions/publicVideos";
import { checkRoles } from "../../../utils/library";
import GroupVideoFormModal from "./GroupVideoFormModal/index.js";
import UploadCosmos from "../UploadCosmos";

const VideosPage = ({
  user,
  publicVideos,
  getPublicVideos,
  isAuthenticated,
}) => {
  const [modelVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getPublicVideos();
  }, [getPublicVideos]);

  if (!isAuthenticated) return <Navigate to="/" />;

  return (
    <div className="videosPage">
      {user && checkRoles(user.roles) && (
        <React.Fragment>
          <UploadCosmos
            modelVisible={modelVisible}
            setModalVisible={setModalVisible}
          />
          {modelVisible && (
            <GroupVideoFormModal setModalVisible={setModalVisible} />
          )}
          <div className="videosHeader">
            <p>사랑하는 엄마</p>
          </div>
          <div className="videos">
            {!modelVisible &&
              publicVideos.length > 0 &&
              publicVideos.map(({ _id, publicVideoGroupName }, i) => (
                <div key={i} className="videoGroup">
                  <Link to={`/videos/${_id}`}>{publicVideoGroupName}</Link>
                </div>
              ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

VideosPage.propTypes = {
  publicVideos: PropTypes.array,
  getPublicVideos: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  publicVideos: state.publicVideos.publicVideos,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getPublicVideos,
})(VideosPage);
