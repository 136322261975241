import axios from "axios";
import { setAlert } from "./alerts";
import {
  PUBLIC_PHOTOS_LOADED,
  PUBLIC_PHOTOS_LOADED_ERROR,
  CREATE_PHOTO_GROUP,
  CREATE_PHOTO_GROUP_ERROR,
  UPLOAD_PUBLIC_PHOTO,
  UPLOAD_PUBLIC_PHOTO_ERROR,
  EDIT_PUBLIC_PHOTO,
  EDIT_PUBLIC_PHOTO_ERROR,
  DELETE_PUBLIC_PHOTO,
  DELETE_PUBLIC_PHOTO_ERROR,
} from "./types.js";
import { uploadFileToS3 } from "../utils/s3uploader";

export const getPublicPhotos = () => async (dispatch) => {
  try {
    const res = await axios.get("/publicPhotos/getPublicPhotos");

    dispatch({
      type: PUBLIC_PHOTOS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PUBLIC_PHOTOS_LOADED_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const createPhotoGroup =
  (publicPhotoGroup = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(publicPhotoGroup);
    try {
      const res = await axios.post(
        "/publicPhotos/createPhotoGroup",
        body,
        config
      );

      dispatch({
        type: CREATE_PHOTO_GROUP,
        payload: res.data,
      });
      dispatch(
        setAlert({ msg: "publicPhotoGroupCreated", alertType: "success" })
      );
    } catch (err) {
      dispatch({
        type: CREATE_PHOTO_GROUP_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      dispatch(
        setAlert({ msg: "publicPhotoGroupNotCreated", alertType: "danger" })
      );
    }
  };

export const uploadPublicPhoto =
  (publicPhoto = {}) =>
  async (dispatch) => {
    await uploadFileToS3({
      key: publicPhoto.publicPhoto,
      file: publicPhoto.publicPhotoFile,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(publicPhoto);
    try {
      const res = await axios.post(
        "/publicPhotos/uploadPublicPhoto",
        body,
        config
      );

      dispatch({
        type: UPLOAD_PUBLIC_PHOTO,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "publicPhotoUploaded", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: UPLOAD_PUBLIC_PHOTO_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      dispatch(
        setAlert({ msg: "publicPhotoNotUploaded", alertType: "danger" })
      );
    }
  };

export const editPublicPhoto =
  (publicPhoto = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(publicPhoto);

    try {
      const res = await axios.put(
        "/publicPhotos/editPublicPhoto",
        body,
        config
      );

      dispatch({
        type: EDIT_PUBLIC_PHOTO,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "photoEdited", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: EDIT_PUBLIC_PHOTO_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
      dispatch(setAlert({ msg: "photoNotEdited", alertType: "danger" }));
    }
  };

export const deletePublicPhoto =
  (groupAndPublicPhotoIds = {}) =>
  async (dispatch) => {
    const { groupId = "", _id = "" } = groupAndPublicPhotoIds;

    try {
      const res = await axios.delete(
        `/publicPhotos/deletePublicPhoto/${groupId}/${_id}`
      );

      dispatch({
        type: DELETE_PUBLIC_PHOTO,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "publicPhotoDeleted", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: DELETE_PUBLIC_PHOTO_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
      dispatch(setAlert({ msg: "publicPhotoNotDeleted", alertType: "danger" }));
    }
  };
