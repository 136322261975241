import {
  SONGS_LOADED,
  SONGS_LOADED_ERROR,
  WRITE_SONG,
  WRITE_SONG_ERROR,
  EDIT_SONG,
  EDIT_SONG_ERROR,
} from "../actions/types";

const initialState = {
  songs: [],
  loading: true,
  error: {},
};

export default function songs(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SONGS_LOADED:
      return {
        ...state,
        songs: payload,
        loading: false,
      };
    case WRITE_SONG:
      return {
        ...state,
        songs: [payload, ...state.songs],
        loading: false,
      };
    case EDIT_SONG:
      const songs = state.songs.map((song) => {
        if (song._id === payload._id) {
          return payload;
        } else {
          return song;
        }
      });
      return {
        ...state,
        songs,
        loading: false,
      };
    case SONGS_LOADED_ERROR:
    case WRITE_SONG_ERROR:
    case EDIT_SONG_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
