import {
  PUBLIC_LETTERS_LOADED,
  PUBLIC_LETTERS_LOADED_ERROR,
  WRITE_PUBLIC_LETTER,
  WRITE_PUBLIC_LETTER_ERROR,
  EDIT_PUBLIC_LETTER,
  EDIT_PUBLIC_LETTER_ERROR,
  DELETE_PUBLIC_LETTER,
  DELETE_PUBLIC_LETTER_ERROR,
} from "../actions/types";

const initialState = {
  publicLetters: [],
  loading: true,
  error: {},
};

export default function publicLetters(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PUBLIC_LETTERS_LOADED:
      return {
        ...state,
        publicLetters: payload,
        loading: false,
      };
    case WRITE_PUBLIC_LETTER:
      return {
        ...state,
        publicLetters: [payload, ...state.publicLetters],
        loading: false,
      };
    case EDIT_PUBLIC_LETTER:
      const publicLetters = state.publicLetters.map((publicLetter) => {
        if (publicLetter._id === payload._id) {
          return payload;
        } else {
          return publicLetter;
        }
      });
      return {
        ...state,
        publicLetters,
        loading: false,
      };
    case DELETE_PUBLIC_LETTER:
      return {
        ...state,
        publicLetters: state.publicLetters.filter(({ _id }) => _id !== payload),
        loading: false,
      };
    case PUBLIC_LETTERS_LOADED_ERROR:
    case WRITE_PUBLIC_LETTER_ERROR:
    case DELETE_PUBLIC_LETTER_ERROR:
    case EDIT_PUBLIC_LETTER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
