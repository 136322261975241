import React, { useRef, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { writeSong, editSong } from "../../../../actions/songs";
import { v4 as uuidv4 } from "uuid";

const SongFormModal = ({
  user,
  modalInfo,
  writeSong,
  editSong,
  setModalInfo,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    songTitle: "",
    song: "",
    songFile: "",
    songDescription: "",
    date: "",
  });
  const { name, songTitle, song, songFile, songDescription } = formData;

  const nameInput = useRef(null);
  const songTitleInput = useRef(null);
  const songDescriptionInput = useRef(null);
  const songInput = useRef(null);
  const songPrePlay = useRef(null);

  const onChange = (e) => {
    if (modalInfo.type === "new") {
      if (e.target.files) {
        songPrePlay.current.src = URL.createObjectURL(e.target.files[0]);
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          song: `${uuidv4()}-song-${e.target.files[0].name}`,
          songFile: e.target.files[0],
        });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else if (modalInfo.type === "edit") {
      if (e.target.name === "name") {
        setFormData({
          name: e.target.value,
          songTitle: songTitle ? songTitle : modalInfo.songTitle,
          songDescription: songDescription
            ? songDescription
            : modalInfo.songDescription,
        });
      } else if (e.target.name === "songTitle") {
        setFormData({
          songTitle: e.target.value,
          name: name ? name : modalInfo.name,
          songDescription: songDescription
            ? songDescription
            : modalInfo.songDescription,
        });
      } else if (e.target.name === "songDescription") {
        setFormData({
          songDescription: e.target.value,
          name: name ? name : modalInfo.name,
          songTitle: songTitle ? songTitle : modalInfo.songTitle,
        });
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    nameInput.current.value = "";
    songTitleInput.current.value = "";
    songDescriptionInput.current.value = "";

    if (modalInfo.type === "new") {
      songInput.current.value = "";
      songPrePlay.current.src = "";

      writeSong({
        userId: user._id,
        name,
        songTitle,
        song,
        songFile,
        songDescription,
        date: new Date(),
      });
    } else if (modalInfo.type === "edit") {
      editSong({ _id: modalInfo._id, name, songTitle, songDescription });
    }
    setModalInfo({
      _id: "",
      name: "",
      songTitle: "",
      song: "",
      songDescription: "",
      type: "",
      visible: false,
    });
  };

  return (
    <div className="songFormModal">
      <button
        className="closeSongFormModal"
        onClick={() =>
          setModalInfo({
            _id: "",
            name: "",
            songTitle: "",
            song: "",
            songDescription: "",
            type: "",
            visible: false,
          })
        }
      >
        닫기
      </button>
      <form onSubmit={onSubmit}>
        <input
          ref={nameInput}
          type="text"
          name="name"
          onChange={onChange}
          placeholder={user.name}
          aria-label="Name"
          defaultValue={modalInfo.name && modalInfo.name}
          required
        />
        <input
          ref={songTitleInput}
          type="text"
          name="songTitle"
          onChange={onChange}
          placeholder="노래제목"
          aria-label="Song title"
          defaultValue={modalInfo.songTitle && modalInfo.songTitle}
          required
        />
        <div className="songPrePlay">
          <audio ref={songPrePlay} controls>
            <source
              src={
                modalInfo.type === "new"
                  ? "#"
                  : `https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/${modalInfo.song}`
              }
            ></source>
          </audio>
        </div>
        {modalInfo.type === "new" && (
          <input
            ref={songInput}
            type="file"
            name="song"
            onChange={onChange}
            placeholder="노래"
            aria-label="Song"
            required
          />
        )}
        <textarea
          ref={songDescriptionInput}
          name="songDescription"
          onChange={onChange}
          placeholder="엄마의 노래^^"
          aria-label="Song"
          defaultValue={modalInfo.songDescription && modalInfo.songDescription}
          required
        />
        <button>{modalInfo.type === "new" ? "보내기" : "수정"}</button>
      </form>
    </div>
  );
};

SongFormModal.propTypes = {
  modalInfo: PropTypes.object.isRequired,
  writeSong: PropTypes.func.isRequired,
  editSong: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  writeSong,
  editSong,
})(SongFormModal);
