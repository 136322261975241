import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./components/styles/styles.scss";
import PrivateRoute from "./components/routing/PrivateRoute/index.js";
import SignInPage from "./components/layout/SignInPage/index.js";
import SignUpPage from "./components/layout/SignUpPage/index.js";
import MainPage from "./components/layout/MainPage/index.js";
import Menu from "./components/layout/Menu/index.js";
import LettersPage from "./components/layout/LettersPage/index.js";
import PhotosPage from "./components/layout/PhotosPage/index.js";
import GroupedPhotos from "./components/layout/PhotosPage/GroupedPhotos/index.js";
import VideosPage from "./components/layout/VideosPage/index.js";
import GroupedVideos from "./components/layout/VideosPage/GroupedVideos/index.js";
import RecipesPage from "./components/layout/RecipesPage/index.js";
import RecipeDetail from "./components/layout/RecipesPage/RecipeDetail/index.js";
import SongsPage from "./components/layout/SongsPage/index.js";
import ActivitiesPage from "./components/layout/ActivitiesPage/index.js";
import AccessPage from "./components/layout/AccessPage/index.js";
import store from "./store";
import { Provider } from "react-redux";
import { loadUser } from "./actions/auth";

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Menu />
        <Routes>
          <Route path="/" element={<SignInPage />} />
          <Route path="/signUp" element={<SignUpPage />} />
          <Route path="/main" element={<PrivateRoute />}>
            <Route path="/main" element={<MainPage />} />
          </Route>
          <Route path="/letters" element={<PrivateRoute />}>
            <Route path="/letters" element={<LettersPage />} />
          </Route>
          <Route path="/photos" element={<PrivateRoute />}>
            <Route path="/photos" element={<PhotosPage />} />
          </Route>
          <Route path="/photos/:groupId" element={<PrivateRoute />}>
            <Route path="/photos/:groupId" element={<GroupedPhotos />} />
          </Route>
          <Route path="/videos" element={<PrivateRoute />}>
            <Route path="/videos" element={<VideosPage />} />
          </Route>
          <Route path="/videos/:groupId" element={<PrivateRoute />}>
            <Route path="/videos/:groupId" element={<GroupedVideos />} />
          </Route>
          <Route path="/recipes" element={<PrivateRoute />}>
            <Route path="/recipes" element={<RecipesPage />} />
          </Route>
          <Route path="/recipes/:recipeId" element={<PrivateRoute />}>
            <Route path="/recipes/:recipeId" element={<RecipeDetail />} />
          </Route>
          <Route path="/songs" element={<PrivateRoute />}>
            <Route path="/songs" element={<SongsPage />} />
          </Route>
          <Route path="/activities" element={<PrivateRoute />}>
            <Route path="/activities" element={<ActivitiesPage />} />
          </Route>
          <Route path="/access" element={<PrivateRoute />}>
            <Route path="/access" element={<AccessPage />} />
          </Route>
          {/* <Route path="/" element={<MainPage />} /> */}
          {/* <Route path="/letters" element={<LettersPage />} /> */}
          {/* <Route path="/photos" element={<PhotosPage />} /> */}
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
