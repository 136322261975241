import axios from "axios";
import { setAlert } from "./alerts";
import {
  PUBLIC_VIDEOS_LOADED,
  PUBLIC_VIDEOS_LOADED_ERROR,
  CREATE_VIDEO_GROUP,
  CREATE_VIDEO_GROUP_ERROR,
  UPLOAD_PUBLIC_VIDEO,
  UPLOAD_PUBLIC_VIDEO_ERROR,
  EDIT_PUBLIC_VIDEO,
  EDIT_PUBLIC_VIDEO_ERROR,
  DELETE_PUBLIC_VIDEO,
  DELETE_PUBLIC_VIDEO_ERROR,
} from "./types.js";
import {
  uploadPublicVideoToS3,
  deletePublicVideoToS3,
} from "../utils/s3uploader";

export const getPublicVideos = () => async (dispatch) => {
  try {
    const res = await axios.get("/publicVideos/getPublicVideos");

    dispatch({
      type: PUBLIC_VIDEOS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PUBLIC_VIDEOS_LOADED_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const createVideoGroup =
  (publicVideoGroup = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(publicVideoGroup);
    try {
      const res = await axios.post(
        "/publicVideos/createVideoGroup",
        body,
        config
      );

      dispatch({
        type: CREATE_VIDEO_GROUP,
        payload: res.data,
      });
      dispatch(
        setAlert({ msg: "publicVideoGroupCreated", alertType: "success" })
      );
    } catch (err) {
      dispatch({
        type: CREATE_VIDEO_GROUP_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      dispatch(
        setAlert({ msg: "publicVideoGroupNotCreated", alertType: "danger" })
      );
    }
  };

export const uploadPublicVideo =
  (publicVideo = {}) =>
  async (dispatch) => {
    await uploadPublicVideoToS3(publicVideo);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(publicVideo);
    try {
      const res = await axios.post(
        "/publicVideos/uploadPublicVideo",
        body,
        config
      );

      dispatch({
        type: UPLOAD_PUBLIC_VIDEO,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "publicVideoUploaded", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: UPLOAD_PUBLIC_VIDEO_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      dispatch(
        setAlert({ msg: "publicVideoNotUploaded", alertType: "danger" })
      );
    }
  };

export const editPublicVideo =
  (publicVideo = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(publicVideo);

    try {
      const res = await axios.put(
        "/publicVideos/editPublicVideo",
        body,
        config
      );

      dispatch({
        type: EDIT_PUBLIC_VIDEO,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "videoEdited", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: EDIT_PUBLIC_VIDEO_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
      dispatch(setAlert({ msg: "videoNotEdited", alertType: "danger" }));
    }
  };

export const deletePublicVideo =
  (videoToDelete = {}) =>
  async (dispatch) => {
    const { groupId = "", _id = "", publicVideo = "" } = videoToDelete;

    await deletePublicVideoToS3(publicVideo);

    try {
      const res = await axios.delete(
        `/publicVideos/deletePublicVideo/${groupId}/${_id}`
      );

      dispatch({
        type: DELETE_PUBLIC_VIDEO,
        payload: res.data,
      });
      dispatch(setAlert({ msg: "publicVideoDeleted", alertType: "success" }));
    } catch (err) {
      dispatch({
        type: DELETE_PUBLIC_VIDEO_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
      dispatch(setAlert({ msg: "publicVideoNotDeleted", alertType: "danger" }));
    }
  };
