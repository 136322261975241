import {
  PUBLIC_PHOTOS_LOADED,
  PUBLIC_PHOTOS_LOADED_ERROR,
  CREATE_PHOTO_GROUP,
  CREATE_PHOTO_GROUP_ERROR,
  UPLOAD_PUBLIC_PHOTO,
  UPLOAD_PUBLIC_PHOTO_ERROR,
  EDIT_PUBLIC_PHOTO,
  EDIT_PUBLIC_PHOTO_ERROR,
  DELETE_PUBLIC_PHOTO,
  DELETE_PUBLIC_PHOTO_ERROR,
} from "../actions/types";

const initialState = {
  publicPhotos: [],
  loading: true,
  error: {},
};

export default function publicPhotos(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PUBLIC_PHOTOS_LOADED:
      return {
        ...state,
        publicPhotos: payload,
        loading: false,
      };
    case CREATE_PHOTO_GROUP:
      return {
        ...state,
        publicPhotos: [...state.publicPhotos, payload],
        loading: false,
      };
    case UPLOAD_PUBLIC_PHOTO:
    case DELETE_PUBLIC_PHOTO:
      const publicPhotos = state.publicPhotos.map((publicPhoto) => {
        if (publicPhoto._id === payload._id) {
          return payload;
        } else {
          return publicPhoto;
        }
      });
      return {
        ...state,
        publicPhotos,
        loading: false,
      };
    case EDIT_PUBLIC_PHOTO:
      const editedPublicPhotos = state.publicPhotos.map((publicPhoto) => {
        if (publicPhoto._id === payload._id) {
          return payload;
        } else {
          return publicPhoto;
        }
      });
      return {
        ...state,
        publicPhotos: editedPublicPhotos,
        loading: false,
      };
    case PUBLIC_PHOTOS_LOADED_ERROR:
    case CREATE_PHOTO_GROUP_ERROR:
    case UPLOAD_PUBLIC_PHOTO_ERROR:
    case DELETE_PUBLIC_PHOTO_ERROR:
    case EDIT_PUBLIC_PHOTO_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
