import React, { useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import PhotoEnlarged from "../../PhotoEnlarged/index.js";
import RecipeFormModal from "../RecipeFormModal/index.js";

const RecipeDetail = ({ recipes, user }) => {
  const { recipeId } = useParams();

  const [modalInfo, setModalInfo] = useState({
    _id: "",
    name: "",
    recipe: "",
    recipePhoto: "",
    type: "",
    visible: false,
  });
  const [photoEnlarged, setPhotoEnlarged] = useState({
    photoEnlarged: "",
    active: false,
  });

  const recipeToShow = recipes.find((recipe) => recipe._id === recipeId);
  return (
    <React.Fragment>
      {!photoEnlarged.active && !modalInfo.visible && (
        <div className="recipe">
          {recipeToShow.userId === user._id && (
            <div className="buttonContainer">
              <button
                onClick={() =>
                  setModalInfo({
                    _id: recipeToShow._id,
                    name: recipeToShow.name,
                    recipe: recipeToShow.recipe,
                    recipePhoto: recipeToShow.recipePhoto,
                    type: "edit",
                    visible: true,
                  })
                }
              >
                수정
              </button>
            </div>
          )}
          <p>{recipeToShow.name}</p>
          <p>{recipeToShow.recipe}</p>
          <div
            className="recipePhoto"
            onClick={() =>
              setPhotoEnlarged({
                photoEnlarged: recipeToShow.recipePhoto,
                active: true,
              })
            }
          >
            <img
              src={`https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/${recipeToShow.recipePhoto}`}
              alt="Recipe"
            />
          </div>
          <p>{moment(recipeToShow.date).format("MMMM Do YYYY, h:mm:ss a")}</p>
        </div>
      )}
      {photoEnlarged.active && !modalInfo.visible && (
        <PhotoEnlarged
          photoEnlarged={photoEnlarged}
          setPhotoEnlarged={setPhotoEnlarged}
        />
      )}
      {modalInfo.visible && !photoEnlarged.active && (
        <RecipeFormModal modalInfo={modalInfo} setModalInfo={setModalInfo} />
      )}
    </React.Fragment>
  );
};

RecipeDetail.propTypes = {
  recipes: PropTypes.array,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  recipes: state.recipes.recipes,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(RecipeDetail);
