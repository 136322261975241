import React, { useEffect } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { getUsers, toggleRole } from "../../../actions/users";
import { checkRoles } from "../../../utils/library";

const AccessPage = ({ user, users, getUsers, toggleRole, isAuthenticated }) => {
  const confirmToggleRole = (_id, name, roles) => {
    const familyMember = checkRoles(roles);
    let confirm = "";
    if (familyMember) {
      confirm = window.confirm(
        `${name}의 접근 권한을 "가족 아님" 으로 바꾸시겠습니까?`
      );
    } else {
      confirm = window.confirm(
        `${name}의 접근 권한을 "가족" 으로 바꾸시겠습니까?`
      );
    }

    if (confirm) {
      const prompt = window.prompt(`다음을 입력하시오: ${name}`);
      if (prompt === name) {
        if (familyMember) {
          const familyRoleIndex = roles.indexOf("family");
          roles.splice(familyRoleIndex, 1);
        } else {
          roles.push("family");
        }
        toggleRole({
          _id,
          roles,
        });
      } else {
        window.alert("잘못 입력 하였습니다");
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  if (!isAuthenticated) return <Navigate to="/" />;

  return (
    <div className="accessPage">
      {user && checkRoles(user.roles) && (
        <React.Fragment>
          <div className="accessHeader">
            <p>사랑하는 엄마</p>
          </div>
          <div className="users">
            {users.length > 0 &&
              users
                .filter((user) => !user.roles.includes("창모"))
                .map(({ _id, name, roles }, i) => (
                  <div key={i} className="user">
                    <div className="buttonContainer">
                      <button
                        onClick={() => confirmToggleRole(_id, name, roles)}
                      >
                        접근 권한 바꾸기
                      </button>
                    </div>
                    <p>
                      {name} - {checkRoles(roles) ? "가족" : "가족 아님"}
                    </p>
                  </div>
                ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

AccessPage.propTypes = {
  getUsers: PropTypes.func.isRequired,
  toggleRole: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  users: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  users: state.users.users,
});

export default connect(mapStateToProps, {
  getUsers,
  toggleRole,
})(AccessPage);
