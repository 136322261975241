import React, { useState, useEffect } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getPublicPhotos } from "../../../actions/publicPhotos";
import GroupPhotoFormModal from "./GroupPhotoFormModal/index.js";
import UploadCosmos from "../UploadCosmos";
import { checkRoles } from "../../../utils/library";

const PhotosPage = ({
  user,
  publicPhotos,
  getPublicPhotos,
  isAuthenticated,
}) => {
  const [modelVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getPublicPhotos();
  }, [getPublicPhotos]);

  if (!isAuthenticated) return <Navigate to="/" />;

  return (
    <div className="photosPage">
      {user && checkRoles(user.roles) && (
        <React.Fragment>
          <UploadCosmos
            modelVisible={modelVisible}
            setModalVisible={setModalVisible}
          />
          {modelVisible && (
            <GroupPhotoFormModal setModalVisible={setModalVisible} />
          )}
          <div className="photosHeader">
            <p>사랑하는 엄마</p>
          </div>
          <div className="photos">
            {!modelVisible &&
              publicPhotos.length > 0 &&
              publicPhotos.map(({ _id, publicPhotoGroupName }, i) => (
                <div key={i} className="photoGroup">
                  <Link to={`/photos/${_id}`}>{publicPhotoGroupName}</Link>
                </div>
              ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

PhotosPage.propTypes = {
  publicPhotos: PropTypes.array,
  getPublicPhotos: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  publicPhotos: state.publicPhotos.publicPhotos,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getPublicPhotos,
})(PhotosPage);
