import React, { useState, useEffect } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPublicLetters,
  deletePublicLetter,
} from "../../../actions/publicLetters";
import LetterFormModal from "./LetterFormModal/index.js";
import moment from "moment";
import "moment/locale/ko";
import { checkRoles } from "../../../utils/library";
import UploadCosmos from "../UploadCosmos";

const LettersPage = ({
  user,
  publicLetters,
  getPublicLetters,
  deletePublicLetter,
  isAuthenticated,
}) => {
  const [modalInfo, setModalInfo] = useState({
    _id: "",
    name: "",
    publicLetter: "",
    type: "",
    visible: false,
  });

  const confirmDeletePublicLetter = (letterId, name) => {
    if (window.confirm("편지를 지우시겠습니까?")) {
      const prompt = window.prompt(`다음을 입력하시오: ${name}`);
      if (prompt === name) {
        deletePublicLetter(letterId);
      } else {
        window.alert("잘못 입력 하였습니다");
      }
    }
  };

  useEffect(() => {
    getPublicLetters();
  }, [getPublicLetters]);

  if (!isAuthenticated) return <Navigate to="/" />;

  return (
    <div className="lettersPage">
      {user && checkRoles(user.roles) && (
        <React.Fragment>
          <UploadCosmos
            modalInfo={{
              _id: "",
              name: "",
              publicLetter: "",
              type: "new",
              visible: !modalInfo.visible,
            }}
            setModalInfo={setModalInfo}
          />
          {modalInfo.visible && (
            <LetterFormModal
              modalInfo={modalInfo}
              setModalInfo={setModalInfo}
            />
          )}
          <div className="lettersHeader">
            <p>사랑하는 엄마</p>
          </div>
          {!modalInfo.visible && (
            <div className="letters">
              {publicLetters.length > 0 &&
                publicLetters.map(
                  ({ _id, userId, name, publicLetter, date }, i) => (
                    <div key={i} className="letter">
                      {userId === user._id && (
                        <div className="buttonContainer">
                          <button
                            onClick={() =>
                              setModalInfo({
                                _id,
                                name,
                                publicLetter,
                                type: "edit",
                                visible: !modalInfo.visible,
                              })
                            }
                          >
                            수정
                          </button>
                          <button
                            onClick={() => confirmDeletePublicLetter(_id, name)}
                          >
                            X
                          </button>
                        </div>
                      )}
                      <p>{name}</p>
                      <p>{publicLetter}</p>
                      <p>{moment(date).format("MMMM Do YYYY, h:mm:ss a")}</p>
                    </div>
                  )
                )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

LettersPage.propTypes = {
  publicLetters: PropTypes.array,
  getPublicLetters: PropTypes.func.isRequired,
  deletePublicLetter: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  publicLetters: state.publicLetters.publicLetters,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getPublicLetters,
  deletePublicLetter,
})(LettersPage);
