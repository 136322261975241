import React, { useEffect, useState } from "react";
import "./index.scss";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 이쁜엄마 from "../../../images/이쁜엄마.jpg";
import 코스모스 from "../../../images/코스모스.png";
import classNames from "classnames";
import { checkRoles } from "../../../utils/library";
import { signOut } from "../../../actions/auth";

const MainPage = ({ user, isAuthenticated, signOut }) => {
  const [showMom, setShowMom] = useState(false);
  const [widthAbove1000, setWidthAbove1000] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setWidthAbove1000(false);
    }
    setInterval(() => setShowMom(true), 100);
    window.addEventListener("resize", () => {
      const width = window.innerWidth;
      if (width < 1000) {
        setWidthAbove1000(false);
      } else {
        setWidthAbove1000(true);
      }
    });
  }, []);

  if (!isAuthenticated) return <Navigate to="/" />;

  return (
    <div className="mainPage">
      {user && checkRoles(user.roles) ? (
        <React.Fragment>
          {widthAbove1000 && (
            <div className={classNames("mom", { show: showMom })}>
              <img src={이쁜엄마} alt="이쁜엄마" />
            </div>
          )}
          <div className="container">
            {!widthAbove1000 && (
              <div className={classNames("mom", { show: showMom })}>
                <img src={이쁜엄마} alt="이쁜엄마" />
              </div>
            )}
            <div className="mainLetter">
              <p>어제 사랑했고,</p>
              <p>여전히 사랑하고,</p>
              <p>항상 사랑했고,</p>
              <p>언제나 사랑할거야💜</p>
              <div className="cosmos">
                <img src={코스모스} alt="코스모스" />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="signOut">
          <p>어플 사용을 위해 창모한테 알려주세요^^</p>
          <button onClick={() => signOut()}>로그아웃</button>
        </div>
      )}
    </div>
  );
};

MainPage.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  signOut: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  signOut,
})(MainPage);
