import React, { useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const PhotoEnlarged = ({ photoEnlarged, setPhotoEnlarged, groupedPhotos }) => {
  const [enlargedPhoto, setEnlargedPhoto] = useState(
    photoEnlarged.photoEnlarged
  );
  let touchStartX = 0;
  let touchEndX = 0;

  const checkDirection = () => {
    const photoIndex = groupedPhotos.findIndex(
      (groupedPhoto) => groupedPhoto.publicPhoto === enlargedPhoto
    );
    const approvedSwipedDistance = 50;
    const swipedRight =
      touchStartX < touchEndX &&
      touchEndX - touchStartX > approvedSwipedDistance &&
      photoIndex > 0;
    const swipedLeft =
      touchStartX > touchEndX &&
      touchStartX - touchEndX > approvedSwipedDistance &&
      photoIndex < groupedPhotos.length - 1;

    if (swipedRight) {
      setEnlargedPhoto(groupedPhotos[photoIndex - 1].publicPhoto);
    }
    if (swipedLeft) {
      setEnlargedPhoto(groupedPhotos[photoIndex + 1].publicPhoto);
    }
  };

  return (
    <div
      className="photoEnlarged"
      onTouchStart={(e) => {
        touchStartX = e.changedTouches[0].screenX;
      }}
      onTouchEnd={(e) => {
        touchEndX = e.changedTouches[0].screenX;
        if (groupedPhotos) {
          checkDirection();
        }
      }}
    >
      <button
        className="closeEnlargedPhoto"
        onClick={() => {
          setPhotoEnlarged({ photoEnlarged: "", active: false });
          document.querySelector("body").style.overflow = "";
        }}
      >
        {"<"}
      </button>
      <img
        src={`https://flower-roads-for-my-mom.s3.ap-northeast-2.amazonaws.com/${enlargedPhoto}`}
        alt="Enlarged img"
      />
    </div>
  );
};

PhotoEnlarged.propTypes = {
  photoEnlarged: PropTypes.object.isRequired,
  setPhotoEnlarged: PropTypes.func.isRequired,
  groupedPhotos: PropTypes.array,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PhotoEnlarged);
